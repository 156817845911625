.button-grid {
    display: grid;
    grid-template-columns: repeat(3, .1fr);
    gap: 20px;
    margin-top: 20px;
}

.grid-button {
    background-color: #007BFF;
    color: white;
    padding: 33px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    max-width: 95vw;
}

.grid-button:hover {
    background-color: #0056b3;
}

.button-image {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.button-text {
    font-size: 1rem;
}

.button-link {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.admin-menu-page {
    display: grid;
    justify-content: center;
    align-items: center;
    min-width: 85vw;
    max-width: 95vw;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    margin-top: 50px;

    padding-top: 50px;
}

.logged-in-container {
    max-width: 95vw;
}

@media (max-width: 768px) {
    .button-grid {
        grid-template-columns: repeat(2, .5fr);
    }
}

  body {
    min-height: 100vh;
  }
  
  .footer {
    width: 100%;
    background-color: var(--navbar-background);
    backdrop-filter: blur(10px);
    z-index: 100;
    padding: 20px 0;
    margin-top: auto;
  }
  
  .footer-content {
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer p {
    margin: 0;
  }
  
  .footer-content p {
    margin-right: 3vw;
  }
  
  .footer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: none;
  }
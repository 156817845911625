.team-management-page-admin {
    max-width: 90vw;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: var(--primary-color);
    color: var(--font-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}


.contract-form-page-admin {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.default {
    display: flex;
    min-width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
}

input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    background-color: white;
}

input:focus {
    outline: none;
    border-color: #007BFF;
}

button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.message {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #e74c3c;
}


.team-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.team-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.team-info {
    flex-grow: 1;
}

.team-edit-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.css-1umw9bq-MuiSvgIcon-root {
    color: rgb(179, 189, 204);
}

.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input {
    color: rgb(179, 189, 204)
}

.MuiSelect-select, .MuiSelect-icon {
    color: rgb(179, 189, 204) !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: rgb(179, 189, 204) !important;
}

.MuiInputLabel-root {
    color: rgb(179, 189, 204) !important;
}

.MuiSelect-select:focus {
    background-color: rgba(255, 255, 255, 0.1);
}

.MuiInputBase-input {
    color: rgb(179, 189, 204) !important;
}


.subcontainer-edit-teams {
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.player-section-edit-teams {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 0.3fr .1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
}

.header-section-edit-teams {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
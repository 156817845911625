.grid-subcontainer-teams {
    display: grid;
    grid-template-columns: 1fr 4fr; 
    grid-template-rows: 1;
    gap: 20px;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.grid-item-teams {
    background-color: #253c5a15;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-item-teams-right {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: right;
    align-items: end;
}

.info-section-teams {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.logo-section-teams {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player-section-teams {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 0.3fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.player-item div {
    margin-bottom: 10px;
}

.header-section-teams {
    text-align: left;
    align-content: baseline;
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.header-section-teams h3 {
    margin: initial;
}

.contact-leader-button {
    color: var(--bright-text-color);
    background-color: var(--secondary-color);
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-style: normal;
    font-size: 20px;
    width: 200px;
    height: 50px;
    padding: 0px 0px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.contact-leader-button:hover {
    background-color: #155ab6;
}

.player-tracker-button {
    color: var(--bright-text-color);
    background-color: var(--secondary-color);
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-style: normal;
    font-size: 20px;
    width: 100px;
    height: 50px;
    padding: 0px 0px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    position: relative;
    top: -15px;
}

.player-tracker-button:hover {
    background-color: #155ab6;
}

/* Responsiveness */
@media (max-width: 768px) {
    .info-section-teams {
        grid-column: span 1; /* Nimmt die gesamte Breite ein */
        width: 100%;
    }
}



.header-section-teams {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-placeholder {
    display: flex;
    align-items: center;
  }
  
  .placeholder-image {
    height: 2.5em; /* Adjust this value to match your desired image size */
    width: auto;
    margin-right: 0.5em;
  }
  
  .header-section-teams h3 {
    margin: 0;
    flex-grow: 1;
  }

  .solo-content p {
    background: #282828;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .social-link svg {
    max-width: 50px;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .solo-content-socials {
    margin: 20px;
    text-align: left;
    /*text-align: center;*/
  }




  .grid-subcontainer-teams {
    position: relative;
  }
  
  .admin-overlay {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .edit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }



  @media (max-width: 768px) {
    .team-h3 {
        padding-right: 5px;
        font-size: 1em;
    }
}
.contract-form-page-admin {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: var(--primary-color);
    color: var(--font-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}


.contract-form-page-admin {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.default {
    display: flex;
    min-width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
}

input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

input:focus {
    outline: none;
    border-color: #007BFF;
}

.ql-editor h1 {
    text-align: center;
}

.quill {
    width: 90vw;
    left: -27vw;
    position: relative;
}

.default button {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}

textarea {
    min-width: 100%;
    max-width: 100%;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
}

button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.message {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #e74c3c;
}
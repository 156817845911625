.grid-subcontainer-coach {
    display: grid;
    grid-template-columns: 1fr 4fr; 
    grid-template-rows: 1;
    gap: 20px;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.grid-item-coach {
    background-color: #253c5a15;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-item-coach-right {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: right;
    align-items: end;
}

.info-section-coach {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.logo-section-coach {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player-section-coach {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 0.3fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.header-section-coach {
    text-align: left;
    align-content: baseline;
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.header-section-coach h3 {
    margin: initial;
}

.contact-coach-button {
    color: var(--bright-text-color);
    background-color: var(--secondary-color);
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-style: normal;
    font-size: 20px;
    width: 200px;
    height: 50px;
    padding: 0px 0px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.contact-coach-button:hover {
    background-color: #155ab6;
}

@media (max-width: 768px) {
    .grid-subcontainer-coach {
        grid-template-columns: 1fr; /* Stack columns on smaller screens */
        gap: 10px;
    }

    .grid-item-coach,
    .grid-item-coach-right {
        padding: 10px;
        text-align: center; /* Center text on smaller screens */
    }

    .info-section-coach,
    .logo-section-coach {
        width: 100%;
    }

    .player-section-coach {
        grid-template-columns: repeat(2, 1fr); /* Reduce number of columns */
        grid-template-rows: auto;
    }

    .header-section-coach {
        grid-template-columns: 1fr; /* Stack header elements */
    }

    .contact-coach-button {
        width: 100%; /* Full width button */
        margin-right: 0;
    }
}
.socials-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--primary-color);
}

.socials-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
}

.socials-container {
    display: flex;
    gap: 20px;
}

.social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    transition: transform 0.3s, color 0.3s;
}

.social-link:hover {
    transform: scale(1.1);
    color: #0073e6;
}

.social-icon {
    font-size: 3em;
    margin-bottom: 10px;
}
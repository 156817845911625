.grid-container-teams {
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: repeat(5, auto);
    gap: 20px;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

/* Responsiveness */
@media (max-width: 768px) {
    .grid-container-teams {
        padding: 0px; /* Adjust padding for smaller screens */
        gap: 10px; /* Adjust gap for smaller screens */
    }
    .grid-subcontainer-teams {
        grid-template-columns: none;
        padding: 0px;
    }
    .logo-section-teams {
        display: none;
    }
    .player-item {
        font-size: .9em;
        padding: 5px;
    }
}
@media (max-width: 800px) {
    .button-container {
        display: flex;
    }
    .gameBtn {
        margin: 10px;
    }
}
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: var(--main-font-family);
    background-color: var(--primary-color);
    color: var(--font-color);
    display: flex;
    justify-content: center;
    align-items: flex-start
}

.grid-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gridCanvas {
    width: 800px;
    height: 800px;
}

.container {
    margin-top: 80px;
    position: relative;
    height: 80%;
    max-width: 1500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 20px;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.grid-item {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-item-right {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: right;
    align-items: end;
}

.info-section {
    grid-column: span 3;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.grid-item-mid {
    align-items: center;
    grid-column: span 10;
}

.scroll-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    position: relative;
}

.trikot-section {
    grid-column: span 3;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trikot-section img {
    z-index: 2;
}

.blue-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0,123,255,0.8) 0%, rgba(0,123,255,0.6) 40%, rgba(0,0,0,0) 70%);
    border-radius: 50%;
    z-index: 1;
}

.empty-section {
    width: 100%;
    height: auto; /* Höhe der leeren Sektion */
}

.tagline {
    background-color: var(--tertiary-color);
    color: var(--text-special-color);
    display: inline;
    padding: 8px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: fit-content;
}

h1 {
    color: var(--bright-text-color);
    margin: 10px 0;
    font-size: 5em;
}

h2 {
    color: var(--bright-text-color);
    margin: 10px 0;
    font-size: 3em;
}

p {
    color: var(--text-color);
    font-size: 1.2em;
    line-height: 1.5;
}

.buttons {
    margin-top: 20px;
}

.page-container {
    text-align: center;
    align-items: center;
}

button {
    color: var(--bright-text-color);
    background-color: var(--secondary-color);
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-style: normal;
    font-size: 20px;
    width: 250px;
    padding: 21px 25px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

button:hover {
    background-color: #155ab6;
}

.match-history {
    background: none;
    border: 1px solid white;
    color: var(--bright-text-color);
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-style: normal;
    font-size: 20px;
    width: 250px;
    padding: 20px 25px;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.match-history:hover {
    background-color: white;
    color: black;
}

.jersey-image {
    width: 100%;
    border-radius: 10px;
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    width: 50px;
    display: none;
}

.scroll-button.left {
    left: 0;
}

.scroll-button.right {
    right: 0;
}

.players-container {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
}

.players-container {
    display: ruby;
}

.player-card {
    position: relative;
    width: 299.25px;
    height: 485px;
    overflow: hidden;
    border-radius: 30px;
    margin: 10px;
    padding: 10px;
  }

  @property --angle{
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  .player-card::after, .player-card::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: conic-gradient(
        from var(--angle), 
        #006aff50, 
        #6914cacc, 
        #2254c0cc, 
        #4b0082cc, /* Indigo */
        #000000cc, /* Black */
        #8a2be2cc, /* BlueViolet */
        #00008bcc, /* DarkBlue */
        #006aff50
    );
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: -1;
    border-radius: 10px;
    animation: 5s spin linear infinite;
  }

  .player-card::before{
    filter: blur(1.5rem);
    opacity: 0.5;
  }

  .player-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
  }

  .player-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.81);
    color: white;
    text-align: center;
    align-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    height: 100%;
    width: 100%;
    left: 0%;
  }

  .player-card:hover .player-info {
    opacity: 1;
  }

  .social-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    max-width: 60%;
    left: 20%;
    position: relative;
   }

    .social-link {
        color: white;
        text-decoration: none;
        flex: 1 1 20%;
        max-width: 25%;
        text-align: center;
    }

  .social-link:hover {
    color: #ffcc00; /* Example hover color */
  }



  .players-container {
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
        box-sizing: content-box;
        padding: 0 25.5%; /* Remove padding on mobile */
    }

    .player-card {
        flex: 0 0 auto;
        margin-right: 16px;
    }



@keyframes reveal {
    0% { opacity: 0; width: 0; }
    100% { opacity: 1; width: 100%; }
  }
  
  .text-appear-animation {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation: reveal 3s steps(40, end) forwards;
  }

@keyframes spin{
  from{
    --angle: 0deg;
  }
  to{
    --angle: 360deg;
  }
}

@media (max-width: 1230) {
    .container {
        top: 500px;
    }
}

/* Responsiveness */
@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        padding: 1vw;
    }

    .players-container {
        padding: 0;
    }

    .info-section {
        grid-column: span 1; /* Takes full width */
        width: 100%;
    }

    .trikot-section {
        grid-column: span 1;
        width: 100%;
    }

    .buttons {
        flex-direction: column; /* Stack buttons vertically */
        align-items: center;
    }

    .match-history {
        width: 100%;
    }

    .text-appear-animation {
        max-width: 80vw;
        font-size: 4em;
    }

    .grid-item {
        max-width: 85vw;
    }

    .grid-item-mid {
        max-width: 90vw;
        overflow: hidden;
        left: 5vw;
        position: relative;
    }

    .grid-item-right {
        left: 5vw;
        position: relative;
        max-width: 90vw;
        padding: 0px;
    }

    .gridCanvas {
        width: 100vw;
    }

    .scroll-button {
        visibility: hidden;
    }

    p {
        max-width: 90vw;
    }

    button {
        width: 100%; /* Make buttons full width */
        margin-bottom: 10px; /* Add space between buttons */
    }

}
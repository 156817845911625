.faq-section {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    border: 1px solid var(--secondary-color);
    border-radius: 10px;
    padding: 20px;
    background-color: rgba(37, 60, 90, 0.1);
  }
  
  .faq-item {
    margin-bottom: 10px;
  }
  
  .faq-question {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    color: var(--bright-text-color);
  }
  
  .faq-icon {
    margin-right: 10px;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding-left: 20px;
    color: var(--text-color);
  }

  @media (max-width: 768px) {
    .faq-section {
      width: 90%;
    }
  }
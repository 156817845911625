.contract-form-page {
    margin-top: 50px;
    display: grid;
    justify-content: center;
    align-items: center;
    min-width: 85vw;
    max-width: 95vw;
    margin: 0 auto;
    padding-top: 60px;
    background-color: var(--primary-color);
    color: var(--font-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.before-code {
  text-align: center;
}

.contract-code-input {
  width: 20vw;
}

.PDFButton {
  width: 100%;
}

  /* Hauptcontainer für den Vertrag */
  #contract {
    color: black; /* Textfarbe komplett schwarz */
    background-color: white; /* Hintergrundfarbe weiß */
    width: 100%; /* Breite auf 100% der Seite setzen */
    height: 100%;
    max-width: 210mm; /* Maximale Breite auf DIN A4 setzen */
    padding: 400mm; /* Innenabstand für saubere Kanten */
    font-family: "Arial", sans-serif; /* Einheitliche Schriftart */
    font-size: 12px; /* Optimale Schriftgröße */
    line-height: 1.5; /* Zeilenabstand für bessere Lesbarkeit */
    box-sizing: border-box; /* Padding und Border in die Breite/Höhe einrechnen */
    outline: none;
    overflow: auto;
  }

  
  /* Überschriften */
  #contract h1, #contract h2 {
    color: black; /* Überschriften auch in Schwarz */
    font-weight: bold; /* Fettgedruckt für bessere Struktur */
    margin-bottom: 10px;
  }
  
  /* Textabsätze */
  #contract p {
    color: black;
    margin-bottom: 10px;
  }
  
  /* Listen (z. B. Vertragsgegenstand) */
  #contract ul {
    color: black;
    margin-left: 20px; /* Abstand zur linken Seite */
    list-style-type: disc; /* Aufzählungspunkte */
  }
  
  
  /* Unterschriftenbereich */
#contract .signature-container {
    display: flex; /* Nebeneinander ausrichten */
    justify-content: space-between; /* Abstand zwischen den beiden Feldern */
  }

  #contract .signature {
    text-align: center; /* Zentriert Text in jedem Unterschriftsblock */
    width: 48%; /* Jeder Block nimmt 48% der Breite ein */
  }


  /* Container für Unterschriften-Linie */
.signature-line {
    display: flex; /* Inhalt horizontal anordnen */
    align-items: center; /* Vertikale Ausrichtung */
    margin-bottom: 10px; /* Abstand zwischen den Zeilen */
  }
  
  /* Text wie "Unterschrift" oder "Ort" */
  .signature-line span {
    flex: 0 0 100px; /* Feste Breite für den Text links */
    text-align: left; /* Text linksbündig */
    font-weight: bold; /* Optional fettgedruckt */
  }
  
  /* Linie für Unterschrift */
  .signature-line .line {
    flex: 1; /* Linie nimmt den restlichen Platz ein */
    height: 1px; /* Dicke der Linie */
    background: black; /* Farbe der Linie */
    margin-left: 10px; /* Abstand zwischen Text und Linie */
  }
.open-partner {
    background: none;
    border: 1px solid white;
    color: var(--bright-text-color);
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-style: normal;
    font-size: 20px;
    width: 250px;
    padding: 20px 25px;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.open-partner:hover {
    background-color: white;
    color: black;
}

.partner-section {
    grid-column: span 3;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner-section img {
    z-index: 2;
    max-width: 100%;
    max-height: 100%;
}

body {
    height: 100%;
}

/* Responsiveness */
@media (max-width: 768px) {

    .partner-section {
        grid-column: span 1;
    }
    .h-partner {
        font-size: 3em;
    }
    .open-partner {
        width: 100%;
    }
}
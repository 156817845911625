.grid-container-merch {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, auto);
    gap: 20px;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.grid-item-merch {
    background-color: #253c5a15;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-item-merch:hover {
    background-color: #155ab6;
}

.grid-item-merch-right {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: right;
    align-items: end;
}

.info-section-merch {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Responsiveness */
@media (max-width: 768px) {
    .info-section-merch {
        grid-column: span 1; /* Nimmt die gesamte Breite ein */
        width: 100%;
    }
    .grid-container-merch {
        grid-template-columns: repeat(1, 80vw);
        grid-template-rows: repeat(5, auto);
        max-width: 300px;
        padding: 0px;
    }
    .grid-item-merch {
        padding: 0px;
        max-width: 300px;
    }
    .h-merch {
        font-size: 3em;
    }
}

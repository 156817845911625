.sw-button {
    background: none;
    border: 1px solid white;
    color: var(--bright-text-color);
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-style: normal;
    font-size: 20px;
    padding: 15px 25px;
    border-radius: 5px;
    margin-right: 2vw;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.sw-button:hover {
    background-color: white;
    color: black;
}
.contact-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: var(--primary-color);
    color: var(--font-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contact-title {
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight-black);
    font-size: 36px;
    margin-bottom: 20px;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: var(--secondary-color);
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-icon {
    font-size: 30px;
    color: var(--bright-text-color);
}

.contact-item p {
    margin: 0;
    font-weight: bold;
}

.contact-item a {
    color: var(--bright-text-color);
    text-decoration: none;
    transition: color 0.3s;
}

.contact-item a:hover {
    color: var(--hover-color);
}
@font-face {
  font-family: 'Kisah Horor Sobat';
  src: url('../public/fonts/Kisah\ Horor\ Sobat.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: var(--primary-color);
}

h1 {
  color: var(--bright-text-color);
  font-family: 'Kisah Horor Sobat', sans-serif;
}

h2 {
  color: var(--bright-text-color);
  font-family: 'Kisah Horor Sobat', sans-serif;
}


.roboto-regular {
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight-regular);
  font-style: normal;
}

.roboto-black {
  font-family: var(--main-font-family);
  font-weight: var(--main-font-weight-black);
  font-style: normal;
  font-size: 100px;
}

.default-div {
  min-width: 95vw;
}